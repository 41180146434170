import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import _ from "lodash";
import AppCard from "../../components/appCard/appCard";
import { connect } from "react-redux";
import moment from "moment";
import en_translation from "../../localization/en/translation.json";
import AppNotification from "../../components/appNotification/appNotification";
import { AppDetail } from "../../components/createProject/models/appDetail";
import { Project } from "../../components/createProject/models/project";
import { getProjectStatusAction } from "../../store/actions/project-status-actions";
import { getAppCategories, getApps } from "../../store/actions/meta-actions";
import { updateProjectDetailsForEMG } from "../../store/actions/emg-policy-actions";
import { ProjectHomeDetails } from "../../components/projectHomeDetails/projectHomeDetails";
import {
  getProjectDetails,
  updateProjectAppPublish,
  flushProjectDetails,
  getMyProjectRightRequest,
  getWrikeSyncSettingsRequest,
  updateWrikeSyncSettingsRequest,
  flushWrikeSyncNotification,
  checkCe4ReadyStatus,
  checkToggleSyncStatus,
  flushToggleSyncStatus,
  extendProjectExpirationRequest,
  extendProjectExpirationFlush,
  showExpirationModel,
  getProjectSupportedFeatures,
  patchProjectUpdateAction,
  getProjectActiveFeatures,
  flushAnnouncements,
  getAnnouncementsRequest,
} from "../../store/actions/project-actions";
import { getProjectCategoriesAction } from "../../store/actions/project-categories-actions";
import { getAppPlatforms } from "../../store/actions/meta-actions";
import { AppPlatformClass } from "../../components/appCard/models/appPlatformClass";
import {
  closeProjectRequest,
  flushCloseProject,
} from "../../store/actions/create-project-actions";
import {
  getAppStatus,
  isProjectAdmin,
  isEyUser,
  isPlatformPending,
  isPlatformError,
  isPlatformReady,
  isProjectUser,
  isPorjectErrored,
  getAzureAppInsights,
  isPortalAdmin,
  isProjectAdminUser,
  setPageBrTitle,
  isFeatureEnabled,
} from "../../utils/helper-utility";
import {
  ACTIVE_PROJECT_STATE,
  isEnabledDropdown,
  NumberCount,
  CheckInTime,
  ExpirationPeriodUnit,
  ExpirationPeriod,
  PROJECT_STATUS_ALL,
} from "./projectDetailConstants";
import { ProjectStatus } from "../../components/projectCard/projectCardConstants";
import { Ce4Data } from "../../models/IHelp";
import { gridAppPlatformKeys } from "../manageUsers/listUsers/listUserConstants";
import {
  EProjectStatuses,
  FeatureSupportedKeys,
  HandlerMode,
  RoutePathKeys,
  ProjectActiveFeatures,
} from "../../utils/Constants";
import { checkAndLoadEMGPolicy } from "../../components/emgPolicy/constants";
import CloseProjectPopup, { FeedbackType } from "../../components/MotifCloseProjectPopup/CloseProjectPopup";
import {
  EProjectCategory,
  EProjectCategoryKey,
} from "../../containers/createProject/formStateModel";
import { AppAlert, AppButton, AppModal, AppSelect } from "../../theme";
import "../home/home.scss";
import "./projectDetails.scss";
import { CrashBoundary } from "../../components/CrashBoundary";
import { MenuActions } from "../../components/appCard/AppCardConstants";
import SharePointSettings from "./SharePointSettings";
import { ProjectDetailsProps, IState } from "./projectDetailsModel";
import { lookupModel } from "../createProject/lookupModel";
import { showActionToast } from "../../store/actions/notification-actions";
import { AssistantContainer } from "../assistantContainer/assistantContainer";
import BreadcrumbComponent from "../../components/breadcrumb/Breadcrumb";
import ProjectAnnouncements from "../../components/projectAnnouncements/projectAnnouncements";
import QuickLinks from "../../components/quickLinks/quickLinks";
import speaker from "../../assets/images/icons/announcement.svg";
import { addProjectDocumentAction, deleteProjectDocumentAction, flushProjectUploadedFileAction } from "../../store/actions/project-actions";
import ProjectGovernanceModal from "./projectGovernanceModal";
import { isExtendedProject } from "./helpers";

type RouteParams = {
  id: string;
  encodedParams: string;
};

type IProps = RouteComponentProps<RouteParams> & ProjectDetailsProps;

class ProjectDetails extends Component<IProps, IState> {
  queryParamsMode: string = "";
  confirmOrChangeTitle: string = "";
  confirmOrChangeContent: string = "";
  hideModalFooterForChangeCategory: boolean = false;
  messageTimer: NodeJS.Timeout | null = null;
  sspIframeRef = React.createRef<HTMLIFrameElement>();

  constructor(props: IProps) {
    super(props);
    this.sspIframeRef = React.createRef();

    this.state = {
      showPubUnPubDialog: false,
      pubUnpubAppId: "",
      notificationMessage: "",
      showNotificationMessage: false,
      openAnnouncementFlyout: false,
      isAnnouncementOpen: false,
      isRoleChecked: false,
      showWorkPlanSyncModal: false,
      wrikeSyncModalRule: "",
      wrikeSyncModalIsEnabled: false,
      isProjectPermissionsLoaded: false,
      handlerMode: "",
      checkInReminderCount: "",
      isCloseModalOpen: false,
      checkInExpirationPeriodText: "",
      showSharePointSettings: false,
      isChangeCategoryModalOpen: false,
      showProjectGovernanceModal: false,
    };
  }

  componentDidMount() {
    const {
      record,
      appCategories,
      getAppCategories,
      apps,
      getApps,
      getProjectDetails,
      match,
      projectStatuses,
      getProjectStatusAction,
      getMyProjectRightRequest,
      projectCategories,
      getProjectCategories,
      getProjectSupportedFeatures,
      allAppPlatforms,
      getAppPlatforms,
      getActiveFeatures,
    } = this.props;

    this.props.flushProjectDetails();
    !projectCategories.length && getProjectCategories();
    !projectStatuses.length && getProjectStatusAction();
    !appCategories.length && getAppCategories();
    !apps.length && getApps();
    !allAppPlatforms?.length && getAppPlatforms();
    match.params.id &&
      getMyProjectRightRequest({
        projectId: match.params.id,
      });
    match.params.id &&
      getProjectDetails({
        id: match.params.id,
      });
    match.params.id &&
      getProjectSupportedFeatures({
        id: match.params.id,
        key: FeatureSupportedKeys.Workflow,
      });
    match.params.id && getActiveFeatures({ id: match.params.id });

    const appInsights = getAzureAppInsights();
    if (record && record.id && appInsights) {
      appInsights.updateProjectDetails({
        CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
      });
    }

    this.checkForEMGPolicy();

    if (match.params.encodedParams) {
      try {
        const query = new URLSearchParams(
          window.atob(match.params.encodedParams)
        );
        this.queryParamsMode = query.get("mode") as string;
        if (
          this.queryParamsMode &&
          this.queryParamsMode.toLowerCase() ===
          HandlerMode.CLOSE_REMINDER.toLowerCase()
        ) {
          this.setState({
            handlerMode: this.queryParamsMode,
            checkInReminderCount: query.get("reminderCount") as string,
          });
        }
      } catch (e) {
        this.props.history.push("/");
      }
    }
    record && record.id && this.updatePageBrTitle();
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      record,
      updating,
      updatingError,
      getProjectDetails,
      match,
      wrikeSyncData,
      isWrikeSyncUpdated,
      flushWrikeSyncNotification,
      checkToggleSyncStatus,
      myProjectRightsData,
      extendProjectExpirationResponse,
      extendProjectExpirationFlush,
      closeProjectResponse,
      closeProjectFlush,
      patchProjectData,
      projectCategories,
      showActionToast,
      accessRight,
    } = this.props;

    if (prevProps.record !== record && record?.id) {
      this.updatePageBrTitle();
      this.getAnnouncementChunk();
    }

    if (prevProps.updating === true && updating === false) {
      let updateMessage = en_translation.project_app_update_successful;
      if (updatingError) {
        updateMessage = en_translation.project_app_update_error;
      }
      this.setState(
        {
          ...this.state,
          notificationMessage: updateMessage,
          showNotificationMessage: true,
        },
        () => {
          setTimeout(this.closeNotification, 3000);
          getProjectDetails({
            id: match.params.id,
          });
        }
      );
    }

    const appInsights = getAzureAppInsights();
    if (prevProps.record !== record && record && record.id) {
      if (appInsights) {
        appInsights.updateProjectDetails({
          CapitalEdgeProjectFriendlyId: record.projectFriendlyId || "",
        });
      }
      this.checkForEMGPolicy();
    }

    if (prevProps.wrikeSyncData !== wrikeSyncData && wrikeSyncData) {
      this.handleWrikeSyncModalData();
    }

    if (
      prevProps.isWrikeSyncUpdated !== isWrikeSyncUpdated &&
      isWrikeSyncUpdated
    ) {
      setTimeout(() => flushWrikeSyncNotification(), 3000);
    }

    if (prevProps.record !== record) {
      if (record?.appPlatformDetails?.find((p) => p.name === "Wrike")) {
        match.params.id && checkToggleSyncStatus(match.params.id);
      }
    }

    if (
      prevProps.myProjectRightsData !== myProjectRightsData &&
      myProjectRightsData &&
      myProjectRightsData.projectId
    ) {
      this.setState({
        ...this.state,
        isProjectPermissionsLoaded: true,
      });
    }

    if (
      prevProps.extendProjectExpirationResponse !==
      extendProjectExpirationResponse &&
      extendProjectExpirationResponse.success
    ) {
      this.setState({ handlerMode: "" });
      this.props.history.push(`/project/${record?.id}`);
      setTimeout(extendProjectExpirationFlush, 3000);
    }

    if (
      prevProps.closeProjectResponse !== closeProjectResponse &&
      closeProjectResponse.success
    ) {
      this.props.history.push("/");
      setTimeout(closeProjectFlush, 3000);
    }

    // Set confirm and change category modal
    if (
      this.queryParamsMode &&
      this.queryParamsMode.toLowerCase() ===
      HandlerMode.ConfirmOrChangeCategory.toLowerCase() &&
      ((prevProps.record !== record && record?.id) ||
        (prevProps.record === record && prevProps.accessRight !== accessRight))
    ) {
      const selectedProjectCategory = projectCategories.find(
        (projectCategory) => projectCategory.id === record?.projectCategoryId
      );
      const projectAccess = accessRight?.projects?.find(
        (item) => item?.projectId === record?.id
      );

      const isProjectAdmin = this.isProjectAdmin();
      if (selectedProjectCategory && isProjectAdmin) {
        if (record && record.id && projectAccess?.hasAcceptedEMG) {
          this.setChangeOrConfirmCategoryModal(selectedProjectCategory, record);
          this.setState({
            isChangeCategoryModalOpen: true,
          });
        }
      } else {
        this.props.history.push("/");
      }
    }
    if (
      patchProjectData &&
      !_.isEqual(prevProps.patchProjectData, patchProjectData)
    ) {
      if (patchProjectData.success) {
        this.setState({
          isChangeCategoryModalOpen: false,
        });
        showActionToast({
          message: en_translation.confirm_project_category_success_message,
          type: "success",
        });
        this.props.history.push(`/project/${record?.id}`);
      } else if (!patchProjectData.success) {
        showActionToast({
          message: en_translation.generic_error_message,
          type: "error",
          redirectURL: "/",
        });
      }
    }
  }

  componentWillUnmount() {
    const { history } = this.props;
    this.props.flushProjectDetails();
    this.props.flushAnnouncements();
    this.props.flushToggleSyncStatus();
    if (!history.location.pathname.includes(RoutePathKeys.PROJECT)) {
      this.resetPageBrTitle();
    }
    if (this.messageTimer) {
      clearTimeout(this.messageTimer);
    }
  }

  checkForEMGPolicy = () => {
    const { accessRight, record, updateProjectDetailsForEMG } = this.props;
    checkAndLoadEMGPolicy(accessRight, updateProjectDetailsForEMG, record);
  };

  openPubUnpubAppDialog = (AppId: string) => {
    this.setState({
      ...this.state,
      showPubUnPubDialog: true,
      pubUnpubAppId: AppId,
    });
  };

  closePubUnpubDialog = () => {
    this.setState({
      ...this.state,
      pubUnpubAppId: "",
      showPubUnPubDialog: false,
    });
  };

  closeNotification = () => {
    this.setState({
      ...this.state,
      showNotificationMessage: false,
      notificationMessage: "",
    });
  };

  updateUpdatePubUnpAppStatus = () => {
    const { record, updateProjectAppPublish } = this.props;
    const app =
      record &&
      record.appDetails &&
      record.appDetails.find(
        (app) => app.appClassId === this.state.pubUnpubAppId
      );

    if (app && record) {
      updateProjectAppPublish({
        appClassId: app.appClassId,
        projectId: record.id,
        status: !app.isPublished,
      });
    }

    this.closePubUnpubDialog();
  };

  handleMoreMenuOptionClick = (
    actionName: string,
    id: string,
    appPlatformClassId: string
  ) => {
    const appCard =
      actionName && id
        ? this.props.record?.appDetails?.find((obj) => obj.appClassId === id)
        : null;
    const appplatform =
      actionName && id
        ? this.props.record?.appPlatformDetails?.find(
          (obj) => obj.appPlatformClassId === appCard?.appPlatformId
        )
        : null;
    const { record } = this.props;

    if (actionName === "open") {
      if (appplatform?.siteURL) {
        let url = appplatform.siteURL;
        if (appplatform.name.toUpperCase() !== gridAppPlatformKeys.WRIKE) {
          url = `${url}${appCard?.appURL || ""}`;
        }
        window.open(url, `${record?.id}_${appCard?.appClassName}`);
      }
    } else if (
      actionName === MenuActions.SETTINGS &&
      this.props.record?.appDetails?.find(
        (appDetail) => appDetail.appPlatformId === appPlatformClassId
      )?.key === gridAppPlatformKeys.SHARE_POINT
    ) {
      this.setState({
        showSharePointSettings: true,
        isSharepointSyncEnabled: record?.isSharePointSyncEnabled,
        sharepointSiteUrl: record?.appPlatformDetails?.find(
          (appPlatformDetail) =>
            appPlatformDetail.appPlatformClassId === appPlatformClassId
        )?.siteURL,
      });
    }
  };

  toggleAnnouncementFlyout = (openAnnouncementFlyout: boolean) => {
    this.setState({ isAnnouncementOpen: true });
    this.setState({ openAnnouncementFlyout });
  };

  toggleWorkPlanSyncModal = (showWorkPlanSyncModal) => {
    this.setState({ showWorkPlanSyncModal });
  };

  isProjectAdmin = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectAdmin(record?.id || "", accessRight, sspPlatformRoles);
  };
  isProjectAdminUser = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectAdminUser(record?.id || "", accessRight, sspPlatformRoles);
  };
  isEyUser = () => {
    return isEyUser(this.props.accessRight);
  };

  isPortalAdmin = () => {
    const { accessRight, sspPlatformRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";

    return isPortalAdmin(portalRole, sspPlatformRoles);
  };

  isProjectUser = () => {
    const { record, sspPlatformRoles, accessRight } = this.props;
    return isProjectUser(
      record?.id || "",
      accessRight?.projects || [],
      sspPlatformRoles
    );
  };

  getAppDetails = (): AppDetail[] => {
    const { record } = this.props;
    let appDetails: AppDetail[] = [];
    if (record?.appDetails?.length) {
      appDetails = record.appDetails;
    }
    this.getRestrictedApps();
    appDetails = _.sortBy(appDetails, ["appClassName"]);
    return appDetails;
  };

  getRestrictedApps = () => {
    const { record, myProjectRightsData, currentUser } = this.props;
    const currentUserApps = _.find(myProjectRightsData.users, {
      mail: currentUser.mail,
    });
    if (currentUserApps && record) {
      record?.appDetails?.map((app) => {
        for (const appDetail of currentUserApps?.appPlatforms) {
          app.notRetricted = appDetail.apps.some((key) => key === app.key);
          if (app.notRetricted) {
            break;
          }
        }
        return app;
      });
    }
  };

  getWorkplanSyncStatus = () => {
    const { projectStatuses, record } = this.props;

    const ce4AppPlatform = record?.appPlatformDetails?.find(
      (p) => p.appPlatformClassId === Ce4Data.PLATFORM_ID
    );
    if (!ce4AppPlatform) {
      return false;
    }

    const ce4PlatformStatus = projectStatuses.find(
      (p) => p.id === ce4AppPlatform.statusId
    );

    return !!(
      ce4PlatformStatus && ce4PlatformStatus.key === EProjectStatuses.RDY
    );
  };

  handleAppCardMenuClick = () => {
    const { match, checkCe4ReadyStatus } = this.props;
    const isCE4PlatformReady = this.getWorkplanSyncStatus();

    if (isCE4PlatformReady) {
      return;
    }

    match.params.id &&
      checkCe4ReadyStatus({
        id: match.params.id,
      });
  };

  handleWorkPlanSyncClick = () => {
    const { getWrikeSyncSettingsRequest, match } = this.props;

    getWrikeSyncSettingsRequest(match.params.id);
    this.toggleWorkPlanSyncModal(true);
  };

  handleWrikeSyncModalData = () => {
    const {
      wrikeSyncData: { isEnabled: wrikeSyncModalIsEnabled, title },
    } = this.props;
    const wrikeSyncModalRule = JSON.stringify(title, null, 2) || "";

    this.setState({ wrikeSyncModalIsEnabled, wrikeSyncModalRule });
  };

  handleWrikeRuleChange = ({ target: { value: wrikeSyncModalRule } }) => {
    this.setState({ wrikeSyncModalRule });
  };

  handleWrikeConfigSave = () => {
    const { updateWrikeSyncSettingsRequest, match } = this.props;
    const { wrikeSyncModalIsEnabled, wrikeSyncModalRule } = this.state;
    const title = JSON.stringify(JSON.parse(wrikeSyncModalRule));

    updateWrikeSyncSettingsRequest({
      id: match.params.id,
      title,
      isEnabled: wrikeSyncModalIsEnabled,
    });
    this.toggleWorkPlanSyncModal(false);
  };

  renderWrikeSyncModalContent = () => {
    const { wrikeSyncModalIsEnabled, wrikeSyncModalRule } = this.state;
    const isValidRule = this.getValidRuleStatus();
    const worlPlanSyncOptions = [
      { value: isEnabledDropdown.YES, label: isEnabledDropdown.YES },
      { value: isEnabledDropdown.NO, label: isEnabledDropdown.NO },
    ];

    return (
      <>
        <div className="workplan-active">
          <div className="workplan-dropdown">
            <label>{en_translation.workplan_sync_active}</label>
            <AppSelect
              id="workplan-sync"
              value={
                wrikeSyncModalIsEnabled
                  ? isEnabledDropdown.YES
                  : isEnabledDropdown.NO
              }
              name={en_translation.workplan_sync_active}
              label=""
              options={worlPlanSyncOptions}
              onChange={(e) =>
                this.setState({
                  wrikeSyncModalIsEnabled:
                    e.target.value === isEnabledDropdown.YES,
                })
              }
            />
          </div>
          <span className="workplan-sync-info-msg">
            {en_translation.workplan_sync_msg}
          </span>
        </div>
        <div className="workplan-sync-rules">
          <label>{en_translation.workplan_sync_rules}</label>
          <textarea
            value={wrikeSyncModalRule}
            onChange={this.handleWrikeRuleChange}
          />
          {!isValidRule && (
            <span className="rule-error">
              {en_translation.workplan_sync_rule_error}
            </span>
          )}
        </div>
      </>
    );
  };

  getValidRuleStatus = () => {
    const { wrikeSyncModalRule } = this.state;

    try {
      JSON.parse(wrikeSyncModalRule);
      return true;
    } catch (error) {
      return false;
    }
  };

  onCloseProject = (id: string, name: string, feedback: FeedbackType,) => {
    this.props.closeProject({ id, name, feedback });
  };

  getDigestPeriod = (period) => {
    const splits = period.split("-");
    const count = splits?.[0];
    const unit = splits?.[1];
    if (unit === ExpirationPeriodUnit.Month) {
      return `${count + " " + ExpirationPeriod.Month}${count === NumberCount._1 ? "" : "s"
        }`;
    } else if (unit === ExpirationPeriodUnit.Week) {
      return `${count + " " + ExpirationPeriod.Week}${count === NumberCount._1 ? "" : "s"
        }`;
    }
    return period;
  };

  isProjectDraft = (projectStatusName: string) => {
    return (
      projectStatusName &&
      projectStatusName.toLowerCase() === ProjectStatus.DRAFT
    );
  };
  isProjectActive = (projectStatusName: string) => {
    return (
      projectStatusName &&
      projectStatusName.toLowerCase() === ACTIVE_PROJECT_STATE.toLowerCase()
    );
  };

  getWorkflowButtonCheck = () => {
    const { projectStatuses, record } = this.props;
    return (
      isPlatformPending(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      ) ||
      isPlatformError(
        projectStatuses,
        record?.appPlatformDetails,
        record?.infrastructureStatusId
      ) ||
      isPorjectErrored(projectStatuses, record?.projectStatus.id!) ||
      this.isProjectDraft(record?.projectStatus?.projectStatusName!) ||
      record?.accessRestrictionKey === PROJECT_STATUS_ALL
    );
  };

  setChangeOrConfirmCategoryModal = (
    selectedProjectCategory: lookupModel | undefined,
    record: Project
  ) => {
    const projectNameKey = "{|projectName|}";
    const projectCategoryKey = "{|projectCategoryName|}";
    if (
      selectedProjectCategory?.key === EProjectCategoryKey.CLIENG.toString()
    ) {
      this.hideModalFooterForChangeCategory = true;
      this.confirmOrChangeTitle =
        en_translation.change_project_category_title_changed;
      this.confirmOrChangeContent =
        en_translation.change_project_category_changed
          .replaceAll(projectNameKey, record.name)
          .replaceAll(projectCategoryKey, selectedProjectCategory?.name || "");
    } else if (record?.projectCategoryConfirmation) {
      this.hideModalFooterForChangeCategory = true;
      this.confirmOrChangeTitle =
        en_translation.change_project_category_title_confirmed;
      this.confirmOrChangeContent =
        en_translation.change_project_category_confirmed
          .replaceAll(projectNameKey, record.name)
          .replaceAll(projectCategoryKey, selectedProjectCategory?.name || "");
    } else {
      this.hideModalFooterForChangeCategory = false;
      this.confirmOrChangeTitle = en_translation.change_project_category_title;
      this.confirmOrChangeContent =
        en_translation.change_project_category_content
          .replaceAll(projectNameKey, record.name)
          .replaceAll(projectCategoryKey, selectedProjectCategory?.name || "");
    }
  };

  handleChangeProjectCategory = () => {
    const { record, history } = this.props;
    const mode = window.btoa("mode=confirmOrChangeCategory");
    history.push(`/project/${record?.id}/project-details/${mode}`);
  };

  handleConfirmProjectCategory = () => {
    const { record } = this.props;
    if (record && record.id) {
      this.setState({ isChangeCategoryModalOpen: false });
      const projectCategoryConfirmation = {
        categoryId: record.projectCategoryId,
      };
      this.props.patchProjectUpdateAction({
        projectId: record.id,
        projectCategoryConfirmation,
      });
    }
  };

  handleProjectCategoryCloseModal = () => {
    const { history, record } = this.props;
    this.setState({ isChangeCategoryModalOpen: false });
    history.push(`/project/${record?.id}`);
    this.queryParamsMode = "";
  };

  updatePageBrTitle() {
    const { record } = this.props;
    if (record && record.name) {
      setPageBrTitle(record.name);
    }
  }

  resetPageBrTitle() {
    setPageBrTitle();
  }
  getAnnouncementChunk() {
    const { getAnnouncementsRequest, record, currentUser } = this.props;
    const projectId = record?.id;
    const userId = currentUser?.id;
    getAnnouncementsRequest({ projectId, pageNumber: 1, userId });
  }

  getAccouncementCount(totalAnnouncement) {
    return totalAnnouncement.filter((item) => item.status === "UnRead").length;
  }

  render() {
    const {
      record,
      appCategories,
      projectStatuses,
      currentUser,
      myProjectRightsData,
      flushWrikeSyncNotification,
      isWrikeSyncUpdated,
      projectCategories,
      extendProjectExpirationResponse,
      isFeatureSupported,
      allAppPlatforms,
      activeFeatures,
      apps,
      addProjectDocument,
      deleteProjectDocument,
      uploadedFile,
      flushProjectUploadedFile,
      announcements,
    } = this.props;

    const {
      showPubUnPubDialog,
      pubUnpubAppId,
      notificationMessage,
      showNotificationMessage,
      showWorkPlanSyncModal,
      wrikeSyncModalIsEnabled,
      isProjectPermissionsLoaded,
      handlerMode,
      checkInReminderCount,
      isCloseModalOpen,
      checkInExpirationPeriodText,
      isChangeCategoryModalOpen,
      openAnnouncementFlyout,
      isAnnouncementOpen,
      showProjectGovernanceModal
    } = this.state;

    if (record && record.id) {
      let selectedApp: AppDetail | undefined;
      let pubUnpubDialogMessage = "";
      let pubUnpubDialogSubMessage = "";
      const appDetails = this.getAppDetails();
      if (pubUnpubAppId) {
        selectedApp = appDetails.find(
          (item) => item.appClassId === pubUnpubAppId
        );
        if (selectedApp && selectedApp.isPublished) {
          pubUnpubDialogMessage = en_translation.unpublish_confirm_message;
          pubUnpubDialogSubMessage =
            en_translation.unpublish_confirm_sub_message;
        } else {
          pubUnpubDialogMessage = en_translation.publish_confirm_message;
          pubUnpubDialogSubMessage = en_translation.publish_confirm_sub_message;
        }
      }

      const isProjectAdmin = this.isProjectAdmin();
      const showNoOfUserCount =
        this.isProjectAdmin() || this.isProjectAdminUser();
      const isEyUser = this.isEyUser();

      const isActive = this.isProjectActive(
        record?.projectStatus?.projectStatusName
      );
      let digestPeriod,
        showProjectAlreadyExtendedMessage = false;
      const isLegacyProject = isFeatureSupported === false;
      if (handlerMode === HandlerMode.CLOSE_REMINDER) {
        if (
          isExtendedProject(record) &&
          record.projectStatus.projectStatusName === ACTIVE_PROJECT_STATE
        ) {
          showProjectAlreadyExtendedMessage = true;
        } else {
          let showExpirationModel = true;
          if (extendProjectExpirationResponse.success) {
            showExpirationModel = false;
          }
          this.props.showExpirationModel(showExpirationModel);

          const expirationPeriod =
            projectCategories.find((c) => c.id === record.projectCategoryId)
              ?.expirationPeriod ?? "???";
          digestPeriod = this.getDigestPeriod(expirationPeriod);
        }
      }
      const keysToKeep = [
        gridAppPlatformKeys.CE4.toLowerCase(),
        gridAppPlatformKeys.CFP.toLowerCase(),
      ];

      const _SharePointSettingEditable =
        isProjectAdmin &&
        isEyUser &&
        isPlatformReady(
          projectStatuses,
          // eslint-disable-next-line array-callback-return
          record?.appPlatformDetails?.filter((appPlatform) => {
            // business requirement is only when application platform cfp or ce4 is part of project
            // along SharePoint can let project admin to edit it
            if (
              record?.appDetails?.some(
                (el) => el.appPlatformId === appPlatform.appPlatformClassId
              )
            ) {
              return allAppPlatforms
                .filter((obj: AppPlatformClass) =>
                  keysToKeep.includes(obj.key.toLowerCase())
                )
                .map((obj: AppPlatformClass) => obj.id)
                .includes(appPlatform.appPlatformClassId);
            }
          }),
          record?.infrastructureStatusId
        );
      const isManageAssistantEnabled = isFeatureEnabled(
        ProjectActiveFeatures.MANAGE_ASSISTANT,
        activeFeatures,
        undefined
      );
      const isAssistantEnabled = record?.copilotDetails?.isActive;
      const isProjectNonActive =
        this.getWorkflowButtonCheck() || !isActive || isLegacyProject;

      const breadcrumbObject = [
        { label: en_translation.app_title, href: "/", active: true },
        {
          label: `${record?.name}`,
          href: `/project/${record?.id}`,
          active: false,
        },
      ];
      const isReady = isPlatformReady(projectStatuses, record?.appPlatformDetails, record?.infrastructureStatusId);
      const extendedMessage = record.extendedByUserName ? en_translation.project_already_extended_message_2.replaceAll("{|projectName|}", record.name)
        .replaceAll("{|extendedUserName|}", record.extendedByUserName)
        .replaceAll("{|extendedUserEmail|}", record.extendedByEmail)
        .replaceAll("{|extendedDate|}", moment(record.extendedOn).format("MM/DD/YYYY")) : 
        en_translation.project_already_extended_message_1.replaceAll("{|projectName|}", record.name);

      return (
        <CrashBoundary>
          <div className="home project-details">
            {/* co-pilot button */}
            {isEyUser && isActive && isAssistantEnabled && (
              <AssistantContainer
                {...record?.copilotDetails}
                isProjectNonActive={isProjectNonActive}
              />
            )}
            {/* co-pilot button */}
            <div className="head-section">
              <div className="page-breadcrumb back-link app-justify-between">
                <BreadcrumbComponent items={breadcrumbObject} />
                {isActive && isReady && (
                  <div className="announcement-wrap">
                    <AppButton
                      variant={"contained"}
                      onClick={() => this.toggleAnnouncementFlyout(true)}
                      className="btn-ey-yellow"
                    >
                      <img src={speaker} alt="icon" width={20} height={20} />
                      {en_translation.edit_project_announcement}
                    </AppButton>
                    {this.getAccouncementCount(announcements) > 0 && (
                      <span className="count-wrap">{this.getAccouncementCount(announcements)}</span>
                    )}
                  </div>
                )}
              </div>

              <ProjectHomeDetails
                projectDetails={record}
                projectStatuses={projectStatuses || []}
                isProjectAdmin={isProjectAdmin}
                showNoOfUserCount={showNoOfUserCount}
                isProjectActive={isActive}
                isProjectNonActive={isProjectNonActive}
                isLegacyProject={isLegacyProject}
                isManageAssistantEnabled={isManageAssistantEnabled && isEyUser}
                toggleAnnouncementFlyout={this.toggleAnnouncementFlyout}
              />

              <div className="app-section">
                <p className="app-sub-title">
                  {en_translation.project_home_app_section}
                </p>
                <div className="project-home-divider-line"></div>
                <div className="row app-tiles">
                  {!!projectStatuses.length && (
                    <>
                      {appDetails?.length > 0 ? (
                        // eslint-disable-next-line
                        appDetails?.map((bundle, key) => {
                          if (appDetails) {
                            const appStatus = getAppStatus(
                              projectStatuses,
                              record.appPlatformDetails,
                              bundle.appPlatformId
                            );
                            const appHotLink = apps.find(
                              (app) => app?.id === bundle?.appClassId
                            )?.appHotlink;

                            return (
                              <section
                                className="col-xs-4 col-lg-3 col-xl-3"
                                key={key}
                              >
                                <AppCard
                                  key={bundle.appClassId}
                                  isNewAppCard={true}
                                  onPublishStatusUpdate={
                                    this.openPubUnpubAppDialog
                                  }
                                  moreMenuEnabled={true}
                                  moreMenuSettingsOptionEnabled={
                                    bundle.key ===
                                    gridAppPlatformKeys.SHARE_POINT
                                  }
                                  appCategories={appCategories}
                                  descriptionEnabled={true}
                                  showDemoBtn={false}
                                  showPublishStatus={true}
                                  isProjectAdmin={isProjectAdmin}
                                  isEyUser={isEyUser}
                                  isPortalAdmin={this.isPortalAdmin()}
                                  handleMoreMenuOptionClick={
                                    this.handleMoreMenuOptionClick
                                  }
                                  appStatus={appStatus}
                                  projectUserName={record?.createdByUserName}
                                  projectEmailAddress={
                                    record?.createdByUserEmail
                                  }
                                  currentUser={currentUser}
                                  workplanSyncEnabled={this.getWorkplanSyncStatus()}
                                  onAppCardMenuclick={
                                    this.handleAppCardMenuClick
                                  }
                                  myProjectRightsData={myProjectRightsData}
                                  onWorkPlanSyncClick={
                                    this.handleWorkPlanSyncClick
                                  }
                                  data={{
                                    id: bundle.appClassId,
                                    name: bundle.appClassName,
                                    appCategoryId: bundle.appCategoryId,
                                    appPlatformClassId: bundle.appPlatformId,
                                    appRelativeURL: '',
                                    channelOnePermittedFlag: '',
                                    isPublished: bundle.isPublished,
                                    demoSiteURL: '',
                                    description: bundle?.appDescription || '',
                                    key: bundle.key || '',
                                    appURL: bundle.appURL,
                                    notRetricted: bundle.notRetricted,
                                    isAppLoaded: isProjectPermissionsLoaded,
                                    appHotlink: appHotLink,
                                  }}
                                />
                              </section>
                            );
                          }
                        })
                      ) : (
                        <div className="no-apps-found">No Apps Selected</div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <QuickLinks isAdmin={this.isProjectAdmin()} isInternalUser={this.isEyUser()} projectId={record.id} />
            </div>
            <AppModal
              newDesign={true}
              size="sm"
              title={en_translation.update_app}
              showModal={showPubUnPubDialog}
              cancelBtnText={en_translation.cancel}
              confirmBtnText={en_translation.confirm}
              onConfirm={this.updateUpdatePubUnpAppStatus}
              onModalClose={this.closePubUnpubDialog}
              children={
                <div className="publish-un-publish-contain">
                  <div>{pubUnpubDialogMessage}</div>
                  <div>{pubUnpubDialogSubMessage}</div>
                </div>
              }
            />
            <ProjectAnnouncements
              isAnnouncementOpen={isAnnouncementOpen}
              flyout={openAnnouncementFlyout}
              closeFlyout={() => this.toggleAnnouncementFlyout(false)}
              isProjectAdmin={isProjectAdmin}
              projectId={record.id}
              currentUser={currentUser}
            />

            <div>
              <AppModal
                title={en_translation.work_plan_sync}
                showModal={showWorkPlanSyncModal}
                cancelBtnText={en_translation.cancel}
                confirmBtnText={en_translation.save}
                onConfirm={this.handleWrikeConfigSave}
                onModalClose={() => this.toggleWorkPlanSyncModal(false)}
                disabledValue={!this.getValidRuleStatus()}
                modalClass={"workplan-sync-modal"}
              >
                {this.renderWrikeSyncModalContent()}
              </AppModal>
            </div>
            {showNotificationMessage && (
              <AppNotification
                message={notificationMessage}
                onCloseNotifcation={this.closeNotification}
                variant={
                  notificationMessage ===
                    en_translation.project_app_update_successful
                    ? "success"
                    : "error"
                }
              />
            )}

            {isWrikeSyncUpdated && (
              <div className="work-plan-notification">
                <AppNotification
                  message={
                    wrikeSyncModalIsEnabled
                      ? en_translation.workplan_sync_enable_msg
                      : en_translation.workplan_sync_disable_msg
                  }
                  onCloseNotifcation={() => flushWrikeSyncNotification()}
                  variant="success"
                />
              </div>
            )}
            {extendProjectExpirationResponse.success && (
              <AppNotification
                message={`${extendProjectExpirationResponse.data
                  ? en_translation.project_extended.replaceAll(
                    "{|period|}",
                    checkInExpirationPeriodText
                  )
                  : en_translation.project_extended_failed
                  }`}
                variant={
                  extendProjectExpirationResponse.data ? "success" : "error"
                }
                onCloseNotifcation={extendProjectExpirationFlush}
              />
            )}
            {handlerMode === HandlerMode.CLOSE_REMINDER &&
              record &&
              (showProjectAlreadyExtendedMessage ? (
                <AppNotification
                  variant="generic"
                  message={extendedMessage}
                  onCloseNotifcation={() => {
                    this.setState({ ...this.state, handlerMode: "" });
                    this.props.history.push(`/project/${record.id}`);
                  }}
                />
              ) : (
                <ExpiryDecisionModal
                  project={record}
                  digestPeriod={digestPeriod}
                  count={checkInReminderCount ?? "unknown"}
                  onExtend={() => {
                    this.setState({
                      ...this.state,
                      showProjectGovernanceModal: true,
                    });
                  }}
                  onCloseProject={() => this.setState({ isCloseModalOpen: true })}
                  onClose={(isExpiraion: boolean) => {
                    this.props.showExpirationModel(false);
                    this.setState({ ...this.state, handlerMode: "" });
                    if(!isExpiraion) this.props.history.push(`/`);
                    else this.props.history.push(`/project/${record.id}`);
                  }}
                />
              ))}
            {record && record.id && (
              <CloseProjectPopup
                show={isCloseModalOpen}
                onClosePopup={() => this.setState({ isCloseModalOpen: false })}
                isPricingFeedback={
                  record.projectCategoryId === EProjectCategory.CLI_ENG
                }
                priced={record.provisioningQuestionsDetails?.isPricingIncluded}
                onProjectClose={(feedback) => this.onCloseProject(record.id as string, record.name, feedback)}
                projectId={record.id}
                paceId={record.paceId}
                uploadedFile={uploadedFile}
                addProjectDocument={addProjectDocument}
                deleteProjectDocument={deleteProjectDocument}
                flushProjectUploadedFile={flushProjectUploadedFile}
              />
            )}
          </div>

          {this.state.showSharePointSettings && (
            <div className="sharepoint-settings-modal">
              <SharePointSettings
                sharePointBaseUrl={this.state.sharepointSiteUrl}
                projectId={record.id}
                isEditable={_SharePointSettingEditable}
                onClose={() => {
                  this.setState({
                    showSharePointSettings: false,
                    sharepointSiteUrl: undefined,
                    isSharepointSyncEnabled: undefined,
                  });
                }}
                sync={this.state.isSharepointSyncEnabled}
              />
            </div>
          )}
          {isChangeCategoryModalOpen && (
            <AppModal
              newDesign={true}
              size="sm"
              modalClass="change-or-confirm-project-category"
              showModal={isChangeCategoryModalOpen}
              onModalClose={this.handleProjectCategoryCloseModal}
              title={this.confirmOrChangeTitle}
              hideFooterSection={this.hideModalFooterForChangeCategory}
              hideFooterCancelBtn={true}
              additionalBtns={
                <React.Fragment>
                  <AppButton
                    type="button"
                    color="secondary"
                    onClick={this.handleChangeProjectCategory}
                  >
                    {en_translation.change}
                  </AppButton>
                  <AppButton
                    type="button"
                    onClick={this.handleConfirmProjectCategory}
                  >
                    {en_translation.confirm}
                  </AppButton>
                </React.Fragment>
              }
            >
              <div
                className="modal-content"
                dangerouslySetInnerHTML={{
                  __html: this.confirmOrChangeContent,
                }}
              ></div>
            </AppModal>
          )}
          {showProjectGovernanceModal &&
            <ProjectGovernanceModal
              onClose={() => this.setState({ ...this.state, showProjectGovernanceModal: false })}
              onConfirmCheckIn={(additionalInfo, engagementUser) => {
                this.props.showExpirationModel(false);
                this.setState({
                  isCloseModalOpen: false,
                  checkInExpirationPeriodText: digestPeriod,
                  showProjectGovernanceModal: false
                });
                this.props.extendProjectExpiration({ payload: { id: record.id, extendedAdditionalInfo: additionalInfo?.trim(), extendedPpeddEmail: engagementUser?.email } });
              }}
            />
          }
        </CrashBoundary>
      );
    } else {
      return null;
    }
  }
}

const ExpiryDecisionModal = ({
  project,
  digestPeriod,
  count,
  onClose,
  onCloseProject,
  onExtend,
}: {
  project: Project;
  digestPeriod: string;
  count: string;
  onClose: (isExpiraion: boolean) => void;
  onCloseProject: () => void;
  onExtend: (id: string, isExpiraion: boolean) => void;
}) => {
  const checkInCount = ((count) => {
    switch (count) {
      case NumberCount._1:
        return CheckInTime.First;
      case NumberCount._2:
        return CheckInTime.Second;
      case NumberCount._3:
        return CheckInTime.Third;
      default:
        return count;
    }
  })(count);

  let checkInContentDuration = digestPeriod;
  if (digestPeriod === NumberCount._3 + " " + ExpirationPeriod.Month + "s") {
    checkInContentDuration = ExpirationPeriod.Quarterly;
  } else if (digestPeriod === NumberCount._1 + " " + ExpirationPeriod.Week) {
    checkInContentDuration = ExpirationPeriod.Weekly;
  }

  const digestContentExpire = en_translation.project_checkIn_content_expire
    .replaceAll("{|projectName|}", `<strong>${project.name}</strong>`)
    .replaceAll("{|period|}", checkInContentDuration);
  const digestContent = en_translation.project_checkIn_content
    .replaceAll("{|projectName|}", `<strong>${project.name}</strong>`)
    .replaceAll("{|period|}", checkInContentDuration);
  const digestNote =
    count === NumberCount._3
      ? en_translation.project_last_checkIn_note.replaceAll("{|projectName|}", project.name)
      : en_translation.project_checkIn_note.replaceAll("{|count|}", checkInCount).replaceAll("{|projectName|}", project.name);


  const isProjectExpire = () => {
    const isHandler = window.location.href.includes("handler");
    let isHandlerExpiration = false;
    if(isHandler) {
      const baseCode = window.location.href.split("handler/")[1];
      const baseParams = atob(baseCode);
      const paramArray = baseParams.split("&");
      paramArray.forEach((param) => {
        const [key, value] = param.split("=");
        if(key === "extensionType" && value === "expiration") {
          isHandlerExpiration = true;
        }
      });
    }
    return isHandlerExpiration;
  };

  const partialStringBold = (data: string, boldKey: string) => data?.replaceAll('{|projectName|}', `<strong>${boldKey}</strong>`)

  return (
    <AppModal
      newDesign={true}
      size="md"
      showModal={true}
      title={en_translation.project_checkIn}
      modalClass="check-in-modal"
      onModalClose={()=> onClose(isProjectExpire())}
      hideFooterCancelBtn={true}
      additionalBtns={
        <React.Fragment>
          <AppButton
            type="button"
            color="secondary"
            onClick={() => onExtend(project.id as string, isProjectExpire())}
          >
            {isProjectExpire() ?
              <>
                {en_translation.keep_project_active.replaceAll(
                  '{|duration|}',
                  digestPeriod
                )}
              </>
              : en_translation.keep_project_active
            }
          </AppButton>
          <AppButton type="button" onClick={onCloseProject}>
            {en_translation.project_checkIn_close}
          </AppButton>
        </React.Fragment>
      }
    >
      <>
        <div className="check-in-note">
          <AppAlert variant="outlined" severity="info">
            <div
              className="content note"
              dangerouslySetInnerHTML={{ __html: digestNote }}
            ></div>
          </AppAlert>
        </div>
        <div className="content">
          {isProjectExpire() ? (
            <>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: digestContentExpire }}
              ></div>
              <p>{en_translation.project_checkIn_content_expire_2}</p>
              <p>{en_translation.project_checkIn_content_expire_3?.replaceAll(
                  '{|projectName|}',
                  project.name
                )}</p>
              <p dangerouslySetInnerHTML={{ __html: partialStringBold(en_translation.project_checkIn_content_expire_4, project.name) }}></p>
            </>
          ) : (
            <>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: digestContent }}
              ></div>
              <p>{en_translation.project_checkIn_content_2}</p>
              <p>{en_translation.project_checkIn_content_3}</p>
              <p dangerouslySetInnerHTML={{ __html: partialStringBold(en_translation.project_checkIn_content_4, project.name) }}></p>
            </>
          )}
        </div>
      </>
    </AppModal>
  );
};

const mapStateToProps = ({
  projects,
  meta,
  projectStatusReducer,
  meReducer,
  projectCategoriesReducer,
  createProjectReducer,
}) => {
  return {
    record: projects.record,
    appCategories: meta.appCategories,
    apps: meta.apps,
    updating: projects.updating,
    updatingError: projects.updatingError,
    projectStatuses: meta.projectStatuses || projectStatusReducer.projectStatus,
    sspPlatformRoles: meta.userRoles,
    accessRight: meReducer.accessRight,
    currentUser: meReducer.currentUser,
    paansPolicy: meReducer.paansPolicy,
    myProjectRightsData: projects.myProjectRightsData,
    wrikeSyncData: projects.wrikeSyncData,
    isWrikeSyncUpdated: projects.isWrikeSyncUpdated,
    isToggleSyncApiAvailable: projects.isToggleSyncApiAvailable,
    projectCategories: projectCategoriesReducer.projectCategories,
    extendProjectExpirationResponse: projects.extendProjectExpirationResponse,
    closeProjectResponse: createProjectReducer.closeProject,
    isFeatureSupported: projects.isFeatureSupported,
    patchProjectData: projects.patchProjectData,
    allAppPlatforms: meta.appPlatforms,
    activeFeatures: projects.activeFeatures,
    announcements: projects.announcements,
  };
};

const mapDispatchToProps = {
  getProjectDetails,
  updateProjectAppPublish,
  getAppCategories,
  getApps,
  getProjectStatusAction,
  flushProjectDetails,
  getMyProjectRightRequest,
  updateProjectDetailsForEMG,
  getWrikeSyncSettingsRequest,
  updateWrikeSyncSettingsRequest,
  flushWrikeSyncNotification,
  checkCe4ReadyStatus,
  checkToggleSyncStatus,
  flushToggleSyncStatus,
  getProjectCategories: getProjectCategoriesAction,
  extendProjectExpiration: extendProjectExpirationRequest,
  extendProjectExpirationFlush: extendProjectExpirationFlush,
  closeProject: closeProjectRequest,
  closeProjectFlush: flushCloseProject,
  showExpirationModel,
  getProjectSupportedFeatures,
  patchProjectUpdateAction,
  showActionToast,
  getAppPlatforms: getAppPlatforms,
  getActiveFeatures: getProjectActiveFeatures,
  flushAnnouncements,
  addProjectDocument: addProjectDocumentAction,
  deleteProjectDocument: deleteProjectDocumentAction,
  flushProjectUploadedFile: flushProjectUploadedFileAction,
  getAnnouncementsRequest: getAnnouncementsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
