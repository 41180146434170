// Helper functions //
import { lookupModel } from "../containers/createProject/lookupModel";
import { AppPlatformDetail } from "../components/createProject/models/appPlatformDetail";
import { ISSPPlatformRole } from "../models/IRoles";
import {
  SSPRolesEnum,
  EProjectStatuses,
  POLICY_URL,
  POLICY_ERROR_CODE_KEY,
  POLICY_ERROR_CODE,
  ProjectActiveFeatures,
  POLICY_ERROR_KEY,
  POLICY_REDIRECT_KEY,
  POLICY_FRIENDLY_ID_KEY,
  POLICY_REDIRECT_URI,
  ERROR_URL,
  UserTypes,
} from "./Constants";
import { IProjectAccess, IAccessRight } from "../models/user";
import moment from "moment-timezone";
import { Project } from "../components/createProject/models/project";
import tr from '../localization/en/translation.json';

export const deepFreeze = (object: object) => {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name];
    if (value && typeof value === "object") {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
};

export const getIconPath = (appCategory) => {
  let iconPath;

  try {
    iconPath = require("../assets/images/icons/" + appCategory?.iconName);
  } catch (err) {
    iconPath = "";
  }

  return iconPath;
};

/**
 * Returns try if project is errored
 * @param projectStatuses
 * @param projectStatus
 */
export const isPorjectErrored = (
  projectStatuses: lookupModel[],
  projectStatus: string
) => {
  const errorId = projectStatuses.find(
    (item) => item.key === EProjectStatuses.EROR
  )?.id;
  return errorId === projectStatus;
};

/**
 * Returns boolean if any platform in a particular project is in pending state or not
 */
export const isPlatformPending = (
  projectStatuses: lookupModel[],
  appPlatformDetails: AppPlatformDetail[] | undefined,
  infrastructureStatusId: string | undefined
): boolean => {
  const pendingId = projectStatuses.find(
    (projectStatus) => projectStatus.key === EProjectStatuses.PND
  )?.id;
  const platform = appPlatformDetails?.find(
    (platform) => platform.statusId === pendingId
  );
  const infrastructure = infrastructureStatusId === pendingId;

  return !!platform || infrastructure;
};

export const isPlatformError = (
  projectStatuses: lookupModel[],
  appPlatformDetails: AppPlatformDetail[] | undefined,
  infrastructureStatusId: string | undefined
): boolean => {
  const errorId = projectStatuses.find(
    (projectStatus) => projectStatus.key === EProjectStatuses.EROR
  )?.id;
  const platform = appPlatformDetails?.find(
    (platform) => platform.statusId === errorId
  );
  const infrastructure = infrastructureStatusId === errorId;

  return !!platform || infrastructure;
};
/**
 * Returns boolean if any platform in a particular project is in ready state or not
 */
export const isPlatformReady = (
  projectStatuses: lookupModel[],
  appPlatformDetails: AppPlatformDetail[] | undefined,
  infrastructureStatusId: string | undefined
): boolean => {
  const readyId = projectStatuses.find(
    (projectStatus) => projectStatus.key === EProjectStatuses.RDY
  )?.id;
  const platform = appPlatformDetails?.find(
    (platform) => platform.statusId === readyId
  );
  const infrastructure = infrastructureStatusId === readyId;

  return !!platform && infrastructure;
};

/**
 * Returns app status based on relevant platform status
 */
export const getAppStatus = (
  projectStatuses: lookupModel[],
  appPlatformDetails: AppPlatformDetail[] | undefined,
  appPlatformClassId: string
): string => {
  const platform = appPlatformDetails?.find(
    (platform) => platform.appPlatformClassId === appPlatformClassId
  );
  const projectStatus = projectStatuses.find(
    (projectStatusItem) => projectStatusItem.id === platform?.statusId
  );
  return projectStatus?.key || "";
};

export const isAppActive = (
  projectStatuses: lookupModel[],
  appPlatformDetails: AppPlatformDetail[] | undefined,
  appPlatformClassId: string
): boolean => {
  const status = getAppStatus(
    projectStatuses,
    appPlatformDetails,
    appPlatformClassId
  );
  return status === EProjectStatuses.RDY;
};

/**
 * Returns the SSP platform tole key based on the user assigned role id
 * @param roleId string
 * @param roles ISSPPlatformRole[]
 */
export const getUserSSPRole = (roleId: string, roles: ISSPPlatformRole[]) => {
  const currentRole = roles.find((item) => item.id === roleId);
  return currentRole?.key || "";
};

/**
 * return if project admin
 * @param projectId string
 * @param  projects IProjectAccess[]
 * @param roles ISSPPlatformRole[]
 */
export const isEyUser = (accessRight: IAccessRight) => {
  return accessRight.isEyUser;
};
const getRole=(
  projectId: string,
  accessRight: IAccessRight,
  roles: ISSPPlatformRole[]
)=>{
  const projects = accessRight?.projects || [];
  const project = projects.find((item) => item.projectId === projectId);
  const roleId =
    project?.projectRoles && project.projectRoles.length > 0
      ? project?.projectRoles[0].id
      : "";
  return  getUserSSPRole(roleId, roles);
}
export const isProjectAdmin = (
  projectId: string,
  accessRight: IAccessRight,
  roles: ISSPPlatformRole[]
) => {
  const role=getRole(projectId,accessRight,roles)
  return role === SSPRolesEnum.PRJADMIN;
};
export const isProjectAdminUser=(
  projectId: string,
  accessRight: IAccessRight,
  roles: ISSPPlatformRole[]
) => {
  const role=getRole(projectId,accessRight,roles)
  return role === SSPRolesEnum.PRJADMINUSER;
}

/**
 * return if project user
 * @param projectId string
 * @param  projects IProjectAccess[]
 * @param roles ISSPPlatformRole[]
 */
export const isProjectUser = (
  projectId: string,
  projects: IProjectAccess[],
  roles: ISSPPlatformRole[]
) => {
  const project = projects.find((item) => item.projectId === projectId);
  const roleId =
    project?.projectRoles && project.projectRoles.length > 0
      ? project?.projectRoles[0].id
      : "";
  const role = getUserSSPRole(roleId, roles);
  return role === SSPRolesEnum.PRJUSR;
};

/**
 * Returns If portal admin key based on the user assigned role id
 * @param roleId string
 * @param roles ISSPPlatformRole[]
 */
export const isPortalAdmin = (roleId: string, roles: ISSPPlatformRole[]) => {
  const role = getUserSSPRole(roleId, roles);
  return role === SSPRolesEnum.PORADMIN;
};

/**
 * Returns If portal admin console key based on the user assigned role id
 * @param roleId string
 * @param roles ISSPPlatformRole[]
 */
export const isAdminConsoleUser = (
  roleId: string,
  roles: ISSPPlatformRole[]
) => {
  const role = getUserSSPRole(roleId, roles);
  return role === SSPRolesEnum.PORADMINCONSOLE;
};

/**
 * Returns Admin roles. can have either Portal Admin User or Admin Console User else none
 * @param roleId string
 * @param roles ISSPPlatformRole[]
 */
export const getAdminConsoleAccess = (
  roleId: string,
  roles: ISSPPlatformRole[]
) => {
  return {
    portalAdmin: isPortalAdmin(roleId, roles),
    portalAdminConsole: isAdminConsoleUser(roleId, roles),
  };
};

/**
 * Returns if Portal User key based on the user assigned role id
 * @param roleId string
 * @param roles ISSPPlatformRole[]
 */
export const isPortalUser = (roleId: string, roles: ISSPPlatformRole[]) => {
  const role = getUserSSPRole(roleId, roles);
  return role === SSPRolesEnum.PORUSR;
};

export const checkCanCreateProject = (accessRight: IAccessRight) => {
  return accessRight.canCreateProject;
};

export const getDateTime = (unixTimestamp) => {
  const date = new Date(unixTimestamp);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dd = String(date.getDate()).padStart(2, "0");
  const monthIndex = date.getMonth();
  const yyyy = date.getFullYear();

  return `${dd}/${months[monthIndex]}/${yyyy}`;
};

/**
 * Returns the local timezone abbreviated based on the timezone
 * report as part of the local date time.
 */
export const timeZoneAbbreviated = (withTimeZoneOffset?: boolean) => {
  var timeZone = moment.tz.guess();
  var time = new Date();
  var timeZoneOffset = time.getTimezoneOffset();

  if (withTimeZoneOffset) {
    return {
      timeDiffInMinutes: timeZoneOffset * -1,
      timeZoneName: moment.tz.zone(timeZone)?.abbr(timeZoneOffset) || "",
    };
  }

  return moment.tz.zone(timeZone)?.abbr(timeZoneOffset) || "";
};

/**
 * Converts hello world to Hello World i.e. Ucwords
 * @param str string
 * @returns string
 */
export const ucwords = (str: string) => {
  const tempStr = str.toLowerCase();
  return tempStr.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, (text) => {
    return text.toUpperCase();
  });
};

/**
 * Return azure app insights added to window
 */
export const getAzureAppInsights = () => {
  return (window as any).appInsights;
};

/**
 * Retrieves the value of a URL query parameter 
 * regardless of case sensitivity
 */

export const getCaseInsensitiveParamValue = (searchParams: any, paramName: string) => {
  // Convert parameter name to lowercase for case-insensitive comparison
  const lowercaseParamName = paramName.toLowerCase();
  // Iterate through all query string parameters
  for (let [key, value] of searchParams.entries()) {

    const lowercaseKey = key.toLowerCase();
    if (lowercaseKey === lowercaseParamName) {
      return value;
    }
  }
  // If no matching parameter is found, return null or an appropriate default value
  return null;
}
/**
 * check if redirected from App Platform and store the
 * details in local storage
 */
export const checkIfAppPlatformPolicyError = (storeUrlParams: Function, clearUrlParams: Function) => {
  const url = new URL(window.location.href);
  if (window.location.href.includes(POLICY_URL)) {

    const errorCode = getCaseInsensitiveParamValue(url.searchParams, POLICY_ERROR_CODE_KEY);
    if (errorCode?.toLowerCase() === POLICY_ERROR_CODE) {
      clearUrlParams();

      const redirectURI = atob(getCaseInsensitiveParamValue(url.searchParams, POLICY_REDIRECT_KEY) || "");

      const projectFriendlyId = getCaseInsensitiveParamValue(url.searchParams, POLICY_FRIENDLY_ID_KEY); // check if URL contains project-friendly id
      const redirectURIParts = redirectURI.split("/"); // index 3 has the project-friendly id

      storeUrlParams({
        errorCode: POLICY_REDIRECT_URI,
        value: redirectURI,
      });
      storeUrlParams({
        errorCode: POLICY_FRIENDLY_ID_KEY,
        value: projectFriendlyId || redirectURIParts[3] || "",
      });
      storeUrlParams({
        errorCode: POLICY_ERROR_KEY,
        value: errorCode,
      });
    }
  } else if (window.location.href.includes(ERROR_URL)) {
    const errorCode = getCaseInsensitiveParamValue(url.searchParams, POLICY_ERROR_CODE_KEY)
    if (errorCode) {
      storeUrlParams({
        errorCode: POLICY_ERROR_CODE_KEY,
        value: errorCode,
      });
    }
  }

};

export const convertB64ToBlob = (b64Data: string, type: string): Blob => {
  const byteCharacters = atob(b64Data);
  // tslint:disable-next-line:no-any
  const byteArrays = [] as any;
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type });
};

export const fileDownLoadUtility = (file) => {
  const date = new Date();
  const hA = date
    .toLocaleString("en-US", { hour: "numeric", hour12: true })
    .split(" ");
  const dayOfWeek = moment().format("ddd");
  const month = moment().subtract(0, "month").startOf("month").format("MMM");
  const day = ("0" + date.getDate()).slice(-2);
  const hrs = hA[0];
  const minutes = date.getMinutes();
  const hrsFormat = hA[1];
  const year = date.getFullYear();
  const blob = convertB64ToBlob(file.fileContents, "application/octet-stream");
  const blobURL =
    window.URL && window.URL.createObjectURL
      ? window.URL.createObjectURL(blob)
      : window.webkitURL.createObjectURL(blob);
  const downloadButton = document.createElement("a");

  downloadButton.style.display = "none";
  downloadButton.href = blobURL;
  downloadButton.setAttribute(
    "download",
    `Project List ${dayOfWeek} ${month} ${day} ${year} ${hrs}_${minutes} ${hrsFormat}.xlsm`
  );
  document.body.appendChild(downloadButton);
  downloadButton.click();
};

export const getPlainText = (htmlMarkup) => {
  const span = document.createElement("span");
  span.innerHTML = htmlMarkup;
  return span.textContent || span.innerText;
};

export const isFeatureEnabled = (
  key: ProjectActiveFeatures,
  activeFeatures: string[],
  record: Project | undefined
) => {
  return (
    (record && !record.id) || (activeFeatures && activeFeatures.includes(key))
  );
};

export const getFutureYears = (startYear, limit) => {
  const currentYear = new Date().getFullYear(),
    years: number[] = [];
  startYear = startYear || currentYear;
  while (years.length <= limit) {
    years.push(startYear++);
  }
  return years;
};

export const validateFormInputForSpecialChar = (val) => {
  if (val && val.toString().length > 0) {
    const r = /[^a-z0-9 .]/gi;
    if (r.test(val.toString().trim().split("")[0])) {
      return true;
    }
    return false;
  }
};

export const formatSplitSpliceJoin = (input: string, delimiter: string, start: number, end: number, replacement: string) => {
    let substrings = input.split(delimiter);
    substrings.splice(start, end, replacement);
    let result = substrings.join(delimiter);
    return result;
}

export const setPageBrTitle = (projectName?: string) => {
  if (projectName) {
    document.title = `${projectName} - ${tr.ey_capital_edge}`;
  } else {
    document.title = tr.app_title;
  }
}

export const arrayBufferToBase64 = (buffer)=> {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const isInternalUser = (userType: string = '') => {
  return userType?.toLowerCase() === UserTypes.Internal.toLowerCase();
};

export const formatDate = (dateString: string | number) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

/**
 * Get the string with matched text in bold
 *
 * @param {string} stringData - origial string
 * @param {string} replaceChar - character to be replaced in bold
 */

export const getStringWithBold = (stringData: string, replaceChar: string) => {
  const regEx = new RegExp(replaceChar, 'gi');
  return stringData.replace(regEx, '<strong>$&</strong>');
};

export const validateGUID = (guid: string) => {
  const guidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return guidPattern.test(guid);
};

export const validateProfessionalUrl = (url: string): boolean => {
  // eslint-disable-next-line
  const urlPattern = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
  return !urlPattern.test(url);
};

export const validateInternalDomain = (url: string): boolean => {
  const internalDomainPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*ey\.com(\/.*)?$/;
  return internalDomainPattern.test(url);
};