import 'date-fns';
import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './appDatePicker.scss';

interface IProps {
  id?: string;
  onChange?: Function;
  format: string;
  value: string;
  label?: string,
  minDate?: Date,
  maxDate?: Date,
  errorMessage?: boolean,
  minDateMessage?: string
  maxDateMessage?: string
  invalidDateMessage?: string
  disableEdit?: boolean
}

interface IState {
  open: boolean
}

class AppDatePicker extends React.Component<IProps, IState> {
  constructor(props: IProps) {
      super(props);
      this.state = {
        open: false
      };
  }

  componentDidMount = () => {
      window.addEventListener('scroll', this.handleCloseCalendar)
  }

  componentWillUnmount = () => {
      window.removeEventListener('scroll', this.handleCloseCalendar)
  }

  handleChange = (e) => {
    const { onChange } = this.props;
  
    this.handleCloseCalendar()
    onChange && onChange(e)
  }
  
  handleCloseCalendar = () => {
    this.setState({ open: false })
  }

  render() {
    const { id = "app-date-picker", value = "", format, label, disableEdit } = this.props;
    const { open } = this.state;

    return (
      <span className="app-date-picker">
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
              label={label}
              disableToolbar
              variant="inline"
              format={format}
              id={id}
              value={value}
              onChange={this.handleChange}
              inputVariant="outlined"
              onClick={() => this.setState({ open: true })}
              onClose={this.handleCloseCalendar}
              open={open}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              minDateMessage={this.props.minDateMessage}
              maxDateMessage={this.props.maxDateMessage}
              error={this.props.errorMessage}
              onFocus={e => disableEdit ? e.target.blur() : null}
              invalidDateMessage={this.props.invalidDateMessage || "Invalid Date"}
              PopoverProps={{
                disableScrollLock: true,
                anchorOrigin: { horizontal: "left", vertical: "bottom" },
                transformOrigin: { horizontal: "left", vertical: "bottom" },
                style: {"top": "-60px"}
              }}
            />
          </MuiPickersUtilsProvider>
      </span>  
    );
  }
 
}

export default AppDatePicker;
